





























































































import { Vue, Component, Watch } from "vue-property-decorator";
import authStore from "@/store/modules/auth";
import {
  IncidentRouter,
  AdminRouter,
  RootRouter,
} from "@/utils/routePathContsant";
import { eventHub } from "@/utils/eventHub";
import helper from "@/utils/helpers";
import incidentroute from "../../router/incident";
// import complainroute from "../../router/complain";
import { Action as action } from "@/store/actionType";
import FacebookLoginButton from "@/components/UIComponents/FacebookLoginButton.vue";
import GoogleLoginButton from "@/components/UIComponents/GoogleLoginButton.vue";
import { CommonStore, JWTModule } from "@/store/modules";
import helpers from "../../utils/helpers";
import SocketHandler from "@/utils/socketHandler";
// import VueRecaptcha from "vue-recaptcha";
import CaptchaValidation from "@/components/captcha.vue";
import { SnotifyPosition } from "vue-snotify";

@Component({
  components: {
    FacebookLoginButton,
    GoogleLoginButton,
    // VueRecaptcha,
    CaptchaValidation,
  },
})
export default class Login extends Vue {
  userName: string = "";
  userPassword: string = "";
  isLoginActive = false;
  rootURL = action.Base;
  allChatSocket: WebSocket | undefined = undefined;
  socket: WebSocket | undefined = undefined;
  isHuman: boolean = false;

  created() {
    //const profile = helper.getUserProfile();
    // if (profile) {
    //   this.$router.push(IncidentRouterPath.IncidentDashboard);
    // }
    eventHub.$on("error", this.errorHandler);
    if (this.isLoggedIn) {
      let token = helpers.getAccessToken();
      this.allChatSocket = SocketHandler.createConnection(
        `chat/?token=${token}`
      );
      this.redirect();
    }
  }
  updated() {}
  get AdminRouter() {
    return AdminRouter;
  }
  get RootRouter() {
    return RootRouter;
  }

  get isLoggedIn() {
    return JWTModule.isLoggedIn;
  }

  get loginDialog() {
    return CommonStore.loginDialog;
  }

  set loginDialog(visible: boolean) {
    CommonStore.setLoginDialog(visible);
  }

  redirect() {
    let profile = helper.getUserProfile() as any;
    if (profile && profile.id) {
      if (profile.userType && profile.userType != "public") {
        this.$router.push(AdminRouter.Root);
      } else {
        this.$router.push({ name: "Home" });
      }
    }
  }

  checkHuman(val: boolean) {
    this.isHuman = val;
    return this.isHuman;
  }

  async login(e: any) {
    let isValid = await this.$validator.validateAll("login");

    // if(!isValid) {
    //   return;
    // }

    if (isValid && !this.isHuman) {
      this.$snotify.error("Incorrect or Empty Captcha", {
        position: SnotifyPosition.rightTop,
      });
      return;
    }
    if (isValid && this.isHuman) {
      this.isLoginActive = true;
      authStore.setLockTokenExpiredMessage(false);
      await authStore.loginUser({
        username: this.userName,
        password: this.userPassword,
        source: "web",
      });
      let userType = helpers.getUserType();
      let token = helpers.getAccessToken();
      if (this.socket) {
        this.socket.close();
      }
      if (token) {
        if (userType && userType == "public") {
          this.socket = SocketHandler.createConnection(`chat/?token=${token}`);
        } else {
          this.socket = SocketHandler.createConnection(
            `chat/0/?token=${token}`
          );
        }
      }
      this.redirect();
      this.isLoginActive = false;
      this.loginDialog = false;
    }
  }

  enterLogin(e: any) {
    this.login(e);
  }

  errorHandler(e: any) {
    this.isLoginActive = false;
  }

  @Watch("loginDialog")
  onLoginChange(newVal: boolean, oldVal: boolean) {
    if (newVal) {
      this.$validator.reset({ scope: "login" });
    }
  }
}
