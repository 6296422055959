


























import { Vue, Component } from "vue-property-decorator";

@Component
export default class CaptchaValidation extends Vue {
    captcha = "";
    inputCaptcha = "";

    created() {
        this.getCaptcha();
    }

    getRandomString() {
        return Math.random().toString(35).substr(2, 3);
    }

    getCaptcha() {
        this.inputCaptcha = "";
        let x = this.getRandomString() + this.getRandomString().toUpperCase();
        this.captcha = x;
    }

    checkValidation() {
        if(this.captcha === this.inputCaptcha) {
            this.$emit("verification", true);
            return;
        }
        this.$emit("verification", false);
    }
}
